import { useLocation } from "@reach/router"
import * as styles from './index.module.scss'

import { Link, navigate } from 'gatsby'
import React, { useState } from "react"
import { isLoggedIn } from '../../services/auth'

import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import AuthenticationForm from '../../components/authentication/form'
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($newPassword: String!, $token: String!){
    resetPassword(input: { newPassword: $newPassword, resetPasswordToken: $token}) {
      success
    }
  }
`

export default function ResetPasswordPage() {
  if (isLoggedIn()) {
    navigate('/presse')
  }

  const search = new URLSearchParams(useLocation().search)
  const token = search.get('reset_password_token')
  const [newPassword, setNewPassword] = useState('')
  const [success, setSuccess] = useState(false)

  const [resetPassword, { data: response, loading, error }] = useMutation(RESET_PASSWORD_MUTATION, {
    variables: { newPassword, token },
    onCompleted: () => {
      setSuccess(true)
    }
  })

  const submitForm = (e) => {
    e.preventDefault()
    resetPassword()
  }

  if (success) {
    return (
      <>
        <Seo title="Passwort vergessen" />
        <Layout>
          <AuthenticationForm title='Passwort vergessen'>
            <p>Ihr Passwort wurde erfolgreich aktualisiert. Bitte melden Sich jetzt an.</p>
            <div className={styles.actions}>
              <Link to='/login'>Anmelden</Link>
            </div>
          </AuthenticationForm>
        </Layout>
      </>
    )
  }
  return (
    <>
      <Seo title="Neues Passwort vergeben" />
      <Layout>
        <AuthenticationForm title='Neues Passwort vergeben'>
          <form onSubmit={submitForm} className={styles.form} data-loading={loading}>
            <div className={styles.errors}>
              {(error && error.message.replace('GraphQL error: ', ''))}
            </div>
            <div className={styles.fields}>
              <input className={styles.field} type="password" placeholder="Neues Passwort" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
              <button className={styles.button} type="submit">Passwort speichern</button>
            </div>
          </form>
          <div className={styles.actions}>
            <Link to='/login'>Anmelden</Link>
          </div>
        </AuthenticationForm>
      </Layout>
    </>
  )
}
